import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Img from 'gatsby-image';
import {
  Container, Row, Col, Button,
} from 'reactstrap';
import { graphql } from 'gatsby';
import MainContent from '../components/mainContent';
import Jumbo from '../components/jumbo';
import Lightbox from '../components/react-image-lightbox';
import Layout from '../components/Layout';

export default class Accommodation extends React.Component {
  state = {
    currentImage: 0,
    lightboxIsOpen: false,
  }

  render() {
    const { data } = this.props;
    const { currentImage, lightboxIsOpen } = this.state;
    const images = data.datoCmsAccomodationPage.imageGallery.map(
      photo => photo.sizes.src,
    );
    return (
      <Layout>
        <HelmetDatoCms seo={data.datoCmsAccomodationPage.seoMetaTags} />

        <Jumbo
          image={data.datoCmsAccomodationPage.headerImage.fluid}
          title={data.datoCmsAccomodationPage.mainHeading}
          subTitle={data.datoCmsAccomodationPage.subHeading}
        />

        <MainContent content={data.datoCmsAccomodationPage.mainText} />

        <Container className="text-center mb-4">
          <Row>
            <Col>
              <Button
                size="lg"
                color="primary"
                className="distributor"
                style={{ marginTop: '-10px' }}
              >
                Book Now
              </Button>
            </Col>
          </Row>
        </Container>

        <Container className="narrow text-center">
          <Row>
            <Col>
              <h2 className="h3">Accommodation designed to make you relax</h2>
            </Col>
          </Row>
          <Row className="py-4">
            {data.datoCmsAccomodationPage.imageGallery.map((photo, index) => (
              <Col key={index} md="3" sm="6" className="mb-4">
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({
                      lightboxIsOpen: true,
                      currentImage: index,
                    });
                  }}
                >
                  <Img fluid={photo.sizes} style={{aspectRatio: "1/1"}}/>
                </div>
              </Col>
            ))}
            {lightboxIsOpen && (
              <Lightbox
                mainSrc={images[currentImage]}
                nextSrc={images[(currentImage + 1) % images.length]}
                prevSrc={
                  images[(currentImage + images.length - 1) % images.length]
                }
                onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
                onMovePrevRequest={() => this.setState({
                  currentImage:
                      (currentImage + images.length - 1) % images.length,
                })
                }
                onMoveNextRequest={() => this.setState({
                  currentImage: (currentImage + 1) % images.length,
                })
                }
              />
            )}
          </Row>
        </Container>
        <Container className="text-center mb-4">
          <Row>
            <Col>
              <Button
                size="lg"
                color="primary"
                className="distributor"
                style={{ marginTop: '-10px' }}
              >
                Book Now
              </Button>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query AccomPageQuery($slug: String!) {
    datoCmsAccomodationPage(slug: { eq: $slug }) {
      mainHeading
      mainText
      subHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      headerImage {
        fluid(maxWidth: 1100, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      imageGallery {
        
        sizes(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;
